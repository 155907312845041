import * as React from 'react';

import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { CustomButton } from 'components/Button';

import TickIcon from 'assets/tick-icon.svg';

const StyledContainerIconDisplay = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledIconDisplay = styled('img')`
  height: 6rem;
  margin-bottom: 1.6rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 1.25rem;
`;

export default function FormDialog({
  openDialog,
  title,
  handleClose,
  handleConfirm,
  handleExited,
  confirmButtonText,
  disabledConfirmButton,
  message,
  ...rest
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        onExited={handleExited}
        sx={[
          {
            '.MuiPaper-root': {
              width: '25rem',
            },
          },
          {
            '.MuiDialogActions-root': {
              marginBottom: '1rem',
            },
          },
        ]}
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <StyledContainerIconDisplay>
            <StyledIconDisplay src={TickIcon} />
            <StyledTypography>{message}</StyledTypography>
          </StyledContainerIconDisplay>
        </DialogContent>
        <DialogActions>
          <StyledContainerIconDisplay>
            <CustomButton
              width="max-content"
              onClick={handleConfirm}
              disabled={disabledConfirmButton}
            >
              {confirmButtonText ? confirmButtonText : 'OK'}
            </CustomButton>
          </StyledContainerIconDisplay>
        </DialogActions>
      </Dialog>
    </div>
  );
}
