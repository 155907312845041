/* global BigInt */
import { useEffect, useState, useContext } from 'react';
import BigNumber from 'bignumber.js';

import { walletContext } from 'context/walletContext';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import InformationDialog from 'components/Dialog/InformationDialog';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { getPortalContract, getTokenContract } from 'utils/contractHelpers';
import { requestAccounts } from 'utils/metamask';

import { randomColor, icoStatus } from 'constants/mockData';

const StyledTopic = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 1.75rem;
`;

const StyledFlexDataTable = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const StyledContainerDataTable = styled('div')`
  height: 60vh;
  width: 80vw;
`;

const StyledContainerTextFieldAmount = styled('div')`
  padding: 1rem;
`;

const StyledFlex = styled('div')`
  display: flex;
  width: 100%;
  font-weight: ${(props) => props.fontWeight};
  justify-content: ${(props) => props.justifyContent};
`;

const MyOrders = () => {
  const { handleSetAccount } = useContext(walletContext);
  const [myIcoTokenList, setMyIcoTokenList] = useState([]);
  const [buyIcoTokenAmount, setBuyIcoTokenAmount] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: '',
    message: '',
    data: null,
    loading: false,
  });
  const [informationDialog, setInformationDialog] = useState({
    open: false,
    message: '',
  });
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRowData(null);
  };

  useEffect(() => {
    getMyICOToken();
  }, []);

  async function getMyICOToken() {
    await requestAccounts(handleSetAccount);
    if (typeof window.ethereum !== 'undefined') {
      try {
        setLoading(true);
        const { signer: portalContract } = getPortalContract();

        const myICOTokenList = await portalContract.getMyIcoToken();
        const formatMyICOTokenListData = await Promise.all(
          myICOTokenList.map(async (data, index) => {
            const tokenContract = getTokenContract(data._icoTokenAddress);
            const tokenName = await tokenContract.name();
            return {
              id: index,
              icoTokenAddress: data._icoTokenAddress,
              icoName: tokenName,
              maxQuota: new BigNumber(data._maxQuota._hex)
                .shiftedBy(-18)
                .toNumber(),
              quotaLeft: new BigNumber(data._quotaLeft._hex)
                .shiftedBy(-18)
                .toNumber(),
              tokenAllAmount: new BigNumber(
                data._tokenAllAmount._hex
              ).shiftedBy(-18),
              description: data._description,
              icoStatus: data._icoStatus,
            };
          })
        );
        setMyIcoTokenList(formatMyICOTokenListData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  }

  const handleCancelIco = async () => {
    try {
      await requestAccounts();
      if (typeof window.ethereum !== 'undefined') {
        setConfirmDialog((prev) => ({ ...prev, loading: true }));
        const row = confirmDialog.data;
        const index = row.id;
        const { signer: portalContract } = getPortalContract();
        const transaction = await portalContract.cancelIco(index);
        await transaction.wait();
        setConfirmDialog((prev) => ({ ...prev, open: false }));
        setBuyIcoTokenAmount('');
        await getMyICOToken();
        setInformationDialog({
          open: true,
          message: 'Cancel ICO Successfully',
        });
      }
    } catch (error) {
      setConfirmDialog((prev) => ({ ...prev, loading: false }));
      console.error(error);
      if (error?.data?.message) {
        alert(error?.data?.message);
      } else {
        alert(error.message);
      }
    }
  };

  const handleCloseIco = async () => {
    try {
      await requestAccounts();
      if (typeof window.ethereum !== 'undefined') {
        setConfirmDialog((prev) => ({ ...prev, loading: true }));
        const row = confirmDialog.data;
        const index = row.id;
        const { signer: portalContract } = getPortalContract();
        const transaction = await portalContract.closeIco(index);
        await transaction.wait();
        setConfirmDialog((prev) => ({ ...prev, open: false }));
        setBuyIcoTokenAmount('');
        await getMyICOToken();
        setInformationDialog({
          open: true,
          message: 'Close ICO Successfully',
        });
      }
    } catch (error) {
      setConfirmDialog((prev) => ({ ...prev, loading: false }));
      console.error(error);
      alert(error?.data?.message);
    }
  };

  const columns = [
    {
      field: 'avatar',
      headerName: '',
      type: 'actions',
      sortable: false,
      width: 70,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Avatar sx={{ bgcolor: randomColor[row.id % 4] }}>
            {row?.icoName?.split('')[0] ?? 'C'}
          </Avatar>
        );
      },
    },
    {
      field: 'icoName',
      headerName: 'ICO name',
      type: 'string',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <div style={{ fontWeight: 'bold' }}>{params.value}</div>;
      },
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Description',
      minWidth: 150,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'tokenAllAmount',
      headerName: 'Token All Amount',
      type: 'number',
      width: 150,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted}`;
      },
    },
    {
      field: 'maxQuota',
      headerName: 'Max Quota (BUSD)',
      type: 'number',
      width: 170,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted}`;
      },
    },
    {
      field: 'quotaLeft',
      headerName: 'Quota Left (BUSD)',
      type: 'number',
      width: 150,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted}`;
      },
    },
    {
      field: 'icoStatus',
      headerName: 'Status',
      Width: 50,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = icoStatus[params.value];
        return `${valueFormatted}`;
      },
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={params.row.icoStatus !== 0}
            onClick={(e) => {
              handleClick(e);
              setRowData(params);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <StyledTopic>
        <StyledTypography sx={{ fontWeight: 'bold' }}>
          My listed ICO token
        </StyledTypography>
      </StyledTopic>
      <StyledFlexDataTable>
        <StyledContainerDataTable>
          <DataGrid
            sx={[
              {
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'solid transparent 1px',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'solid transparent 1px',
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'solid transparent 1px',
                },
              },
            ]}
            loading={loading}
            rows={myIcoTokenList}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
          />
        </StyledContainerDataTable>
      </StyledFlexDataTable>
      <Menu
        id="positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setConfirmDialog((prev) => ({
              ...prev,
              open: true,
              type: 'close-ico',
              message: 'Are you sure to close ICO ?',
              data: rowData,
            }));
            setAnchorEl(null);
          }}
        >
          Close ICO
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfirmDialog((prev) => ({
              ...prev,
              open: true,
              type: 'cancel-ico',
              message: 'Are you sure to cancel ICO ?',
              data: rowData,
            }));
            setAnchorEl(null);
          }}
        >
          Cancel ICO
        </MenuItem>
      </Menu>
      <ConfirmDialog
        openDialog={confirmDialog.open}
        title={confirmDialog.message}
        confirmButtonText={
          confirmDialog.loading
            ? 'Waiting for transaction to confirm...'
            : 'Confirm'
        }
        disabledConfirmButton={confirmDialog.loading}
        disabledCancelButton={confirmDialog.loading}
        handleConfirm={() => {
          if (confirmDialog.type === 'cancel-ico') {
            handleCancelIco();
          } else if (confirmDialog.type === 'close-ico') {
            handleCloseIco();
          }
        }}
        handleClose={(e, reason) => {
          if (reason === 'backdropClick') {
            return;
          } else {
            setConfirmDialog((prev) => ({ ...prev, open: false }));
          }
        }}
        handleExited={() => {
          setConfirmDialog((prev) => ({
            ...prev,
            message: '',
            data: null,
            loading: false,
            type: '',
          }));
          setBuyIcoTokenAmount('');
        }}
      ></ConfirmDialog>
      <InformationDialog
        openDialog={informationDialog.open}
        message={informationDialog.message}
        handleConfirm={() => {
          setInformationDialog({ open: false, message: '' });
        }}
      ></InformationDialog>
    </>
  );
};

export default MyOrders;
