import { useEffect, useState, useContext } from 'react';
import BigNumber from 'bignumber.js';

import { walletContext } from 'context/walletContext';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';

import { getPortalContract, getTokenContract } from 'utils/contractHelpers';
import { requestAccounts } from 'utils/metamask';
import { randomColor, orderStatus } from 'constants/mockData';

const StyledTopic = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 1.75rem;
`;

const StyledFlexDataTable = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const StyledContainerDataTable = styled('div')`
  height: 60vh;
  width: 50vw;
`;

const StyledFlex = styled('div')`
  display: flex;
  width: 100%;
  font-weight: ${(props) => props.fontWeight};
  justify-content: ${(props) => props.justifyContent};
`;

const MyOrders = () => {
  const { handleSetAccount } = useContext(walletContext);
  const [userOrderList, setUserOrderList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserOrderList();
  }, []);

  async function getUserOrderList() {
    await requestAccounts(handleSetAccount);
    if (typeof window.ethereum !== 'undefined') {
      try {
        setLoading(true);
        const { signer: portalContract } = getPortalContract();

        const userOrderList = await portalContract.getUserOrder();
        const formatUserOrderListData = await Promise.all(
          userOrderList.map(async (data, index) => {
            const tokenContract = getTokenContract(data._icoTokenAddress);
            const tokenName = await tokenContract.name();
            return {
              id: index,
              icoTokenAddress: data._icoTokenAddress,
              icoName: tokenName,
              amount: new BigNumber(data._amount._hex).shiftedBy(-18),
              orderStatus: orderStatus[data._orderStatus],
            };
          })
        );
        setUserOrderList(formatUserOrderListData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        if (error?.data?.message) {
          alert(error?.data?.message);
        } else {
          alert(error.message);
        }
      }
    }
  }

  const columns = [
    {
      field: 'avatar',
      headerName: '',
      type: 'actions',
      sortable: false,
      width: 70,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Avatar sx={{ bgcolor: randomColor[row.id % 4] }}>
            {row?.icoName?.split('')[0] ?? 'C'}
          </Avatar>
        );
      },
    },
    {
      field: 'icoName',
      headerName: 'ICO name',
      type: 'string',
      width: 170,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return <div style={{ fontWeight: 'bold' }}>{params.value}</div>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 150,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted}`;
      },
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      width: 150,
      editable: false,
      sortable: false,
      renderHeader: () => {
        return (
          <StyledFlex justifyContent="center" fontWeight="bold">
            Status
          </StyledFlex>
        );
      },
      renderCell: (params) => {
        return <StyledFlex justifyContent="center">{params.value}</StyledFlex>;
      },
    },
  ];

  return (
    <>
      <StyledTopic>
        <StyledTypography sx={{ fontWeight: 'bold' }}>
          My orders
        </StyledTypography>
      </StyledTopic>
      <StyledFlexDataTable>
        <StyledContainerDataTable>
          <DataGrid
            sx={[
              {
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'solid transparent 1px',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'solid transparent 1px',
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'solid transparent 1px',
                },
              },
            ]}
            loading={loading}
            rows={userOrderList}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
          />
        </StyledContainerDataTable>
      </StyledFlexDataTable>
    </>
  );
};

export default MyOrders;
