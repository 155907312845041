import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { colors } from 'theme';

import { CustomButton } from 'components/Button';

export default function FormDialog({
  openDialog,
  title,
  handleClose,
  handleConfirm,
  handleExited,
  confirmButtonText,
  cancelButtonText,
  disabledConfirmButton,
  disabledCancelButton,
  children,
  ...rest
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        sx={[
          {
            '.MuiPaper-root': {
              width: '30rem',
            },
          },
        ]}
        TransitionProps={{
          onExited: handleExited,
        }}
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <CustomButton
            width="max-content"
            backgroundColor="white"
            fontColor={colors.primary}
            border={`1px solid ${colors.primary}`}
            onClick={handleClose}
            disabled={disabledCancelButton}
          >
            {cancelButtonText ? cancelButtonText : 'Cancel'}
          </CustomButton>
          <CustomButton
            width="max-content"
            onClick={handleConfirm}
            disabled={disabledConfirmButton}
          >
            {confirmButtonText ? confirmButtonText : 'Confirm'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
