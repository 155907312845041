import React from 'react';
import { styled } from '@mui/material';

import Button from '@mui/material/Button';

const StyledButton = styled(Button)`
  &&& {
    width: ${(props) => props.width};
    font-weight: bold;
    border-radius: 0.6rem;
    text-transform: none;
    padding: 1rem 1.9rem;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.fontColor};
    font-size: ${(props) => props.fontSize};
    min-width: ${(props) => props.width};
    border: ${(props) => props.border};
    height: ${(props) => props.height};
    text-decoration: none;
    cursor: pointer;
    :hover {
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
    }
    &&&.MuiButton-root:hover {
      box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
    }
    &&&.MuiButtonBase-root.Mui-disabled {
      /* background-color: #c8c8c8;
      color: #838383; */
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
      border-color: transparent;
    }
  }
`;

export const CustomButton = ({
  fontSize,
  width,
  height = '2.5rem',
  fontColor = 'white',
  backgroundColor = '#00b8de',
  border = 'none',
  disableRipple = false,
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <StyledButton
      width={width}
      fontSize={fontSize}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      border={border}
      height={height}
      onClick={onClick}
      disabled={disabled}
      disableRipple={disableRipple}
    >
      {children}
    </StyledButton>
  );
};
