import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { walletContext } from 'context/walletContext';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { CustomButton } from 'components/Button';

import { requestAccounts } from 'utils/metamask';

const Box = styled('div')`
  height: 4.5rem;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledTextLogoContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledContainerAppBarItems = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem 0 5rem;
`;

const StyledContainerMenuButton = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const StyledContainer = styled('div')`
  max-height: calc(100vh - 4.5rem);
  width: 100%;
  padding-top: 3rem;
`;

const AppBar = ({ children }) => {
  let navigate = useNavigate();
  const [account, setAccount] = useState('');

  const handleSetAccount = (account) => {
    setAccount(account);
  };

  const formatStringAccount = (account) => {
    if (account.length > 6) {
      const newString = `${account.substr(0, 3)}...${account.substr(
        account.length - 3,
        account.length
      )}`;
      return newString;
    }
    return account;
  };

  return (
    <walletContext.Provider value={{ account, handleSetAccount }}>
      <Box>
        <StyledContainerAppBarItems>
          <StyledTextLogoContainer
            onClick={() => {
              navigate('/');
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>ICO Portal</Typography>
          </StyledTextLogoContainer>
          <StyledContainerMenuButton>
            <CustomButton
              onClick={() => {
                navigate('/add-new-ico');
              }}
            >
              Add new ICO
            </CustomButton>
            <CustomButton
              onClick={() => {
                navigate('/');
              }}
            >
              ICO list
            </CustomButton>
            <CustomButton
              onClick={() => {
                navigate('/my-orders');
              }}
            >
              My orders
            </CustomButton>
            <CustomButton
              onClick={() => {
                navigate('/my-listed-ico-token');
              }}
            >
              My listed ICO token
            </CustomButton>
            <CustomButton
              onClick={async () => {
                await requestAccounts(handleSetAccount);
              }}
            >
              <AccountBalanceWalletIcon />
              {account ? formatStringAccount(account) : 'Connect to wallet'}
            </CustomButton>
          </StyledContainerMenuButton>
        </StyledContainerAppBarItems>
      </Box>
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </walletContext.Provider>
  );
};

export default AppBar;
