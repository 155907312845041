import React from 'react';
import { ThemeProvider } from '@mui/material';

import Router from 'router';

import { theme } from 'theme';
import 'theme/globals.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}
