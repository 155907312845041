/* global BigInt */
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

import { walletContext } from 'context/walletContext';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TextField } from 'components/TextField';
import { CustomButton } from 'components/Button';
import InformationDialog from 'components/Dialog/InformationDialog';

import { hexToInt } from 'utils';
import { requestAccounts } from 'utils/metamask';
import { getERC20Contract, getPortalContract } from 'utils/contractHelpers';
import { getPortalAddress } from 'utils/addressHelpers';

const StyledFormContainer = styled('div')`
  margin: auto;
  width: 50vw;
`;

const StyledTypography = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const StyledFlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const AddNewIco = () => {
  const navigate = useNavigate();
  const { handleSetAccount } = useContext(walletContext);
  const [icoTokenAddress, setIcoTokenAddress] = useState('');
  const [tokenAllAmount, setTokenAllAmount] = useState('');
  const [maxQuota, setMaxQuota] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [informationDialog, setInformationDialog] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    (async () => {
      await requestAccounts(handleSetAccount);
    })();
  }, []);

  const handleSubmitNewIco = async () => {
    try {
      const [account] = await requestAccounts(handleSetAccount);
      if (typeof window.ethereum !== 'undefined') {
        setLoading(true);
        const Erc20Contract = getERC20Contract(icoTokenAddress);
        const valueInHex = await Erc20Contract.allowance(
          account,
          getPortalAddress()
        );
        const value = hexToInt(valueInHex);
        if (value === 0) {
          const transaction = await Erc20Contract.approve(
            getPortalAddress(),
            BigInt(2 ** (256 - 1))
          );
          await transaction.wait();
        }
        const tokenAllAmountBigInt = ethers.utils.parseEther(tokenAllAmount);
        const maxQuotaBigInt = ethers.utils.parseEther(maxQuota);
        const { signer: portalContract } = getPortalContract();
        const transaction = await portalContract.listNewToken(
          icoTokenAddress,
          tokenAllAmountBigInt,
          maxQuotaBigInt,
          description
        );
        await transaction.wait();
        setLoading(false);
        setInformationDialog({
          open: true,
          message: 'Add New ICO Successfully',
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
      if (error?.data?.message) {
        alert(error?.data?.message);
      } else {
        alert(error.message);
      }
    }
  };

  return (
    <>
      <StyledFormContainer>
        <StyledTypography sx={{ fontWeight: 'bold' }}>
          Add new ICO
        </StyledTypography>
        <StyledFlexColumn>
          <TextField
            label="ICO Token Address*"
            value={icoTokenAddress}
            onChange={(e) => setIcoTokenAddress(e.target.value)}
            fullWidth
          />
          <TextField
            label="Amount*"
            value={tokenAllAmount}
            onChange={(e) => setTokenAllAmount(e.target.value)}
            fullWidth
          />
          <TextField
            label="Max Quota (BUSD)*"
            value={maxQuota}
            onChange={(e) => setMaxQuota(e.target.value)}
            fullWidth
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          <CustomButton
            width="max-content"
            onClick={handleSubmitNewIco}
            disabled={loading}
          >
            {loading
              ? 'Waiting for transaction to confirm...'
              : 'Submit New ICO'}
          </CustomButton>
        </StyledFlexColumn>
      </StyledFormContainer>
      <InformationDialog
        openDialog={informationDialog.open}
        message={informationDialog.message}
        handleConfirm={() => {
          setInformationDialog({ open: false, message: '' });
          navigate('/');
        }}
      ></InformationDialog>
    </>
  );
};

export default AddNewIco;
