import { ethers } from 'ethers';

// ABI
import PortalAbi from 'constants/abi/Portal.json';
import BusdAbi from 'constants/abi/BUSD.json';
import PptkAbi from 'constants/abi/PPTK.json';
import IERC20Abi from 'constants/abi/IERC20.json';

// Addresses
import {
  getPortalAddress,
  getBusdAddress,
  getPptkAddress,
} from './addressHelpers';

const getProviderContract = (abi, address) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const contract = new ethers.Contract(address, abi, provider);
  return contract;
};

const getSignerContract = (abi, address) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(address, abi, signer);
  return contract;
};

export const getPortalContract = () => ({
  provider: getProviderContract(PortalAbi.abi, getPortalAddress()),
  signer: getSignerContract(PortalAbi.abi, getPortalAddress()),
});

export const getTokenContract = (icoTokenAddress) => {
  const abi = [
    // Some details about the token
    'function name() view returns (string)',
  ];
  const tokenContract = getSignerContract(abi, icoTokenAddress);
  return tokenContract;
};

export const getERC20Contract = (icoTokenAddress) => {
  const contract = getSignerContract(IERC20Abi.abi, icoTokenAddress);
  return contract;
};
