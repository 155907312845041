import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import AppBar from 'components/AppBar';
import IcoList from 'pages/IcoList';
import AddNewIco from 'pages/AddNewIco';
import MyOrders from 'pages/MyOrders';
import MyListedIcoToken from 'pages/MyListedIcoToken';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppBar />}>
          <Route index element={<IcoList />} />
          <Route path="add-new-ico" element={<AddNewIco />} />
          <Route path="my-orders" element={<MyOrders />} />
          <Route path="my-listed-ico-token" element={<MyListedIcoToken />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
