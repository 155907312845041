import { styled } from '@mui/material';
import MUITextField from '@mui/material/TextField';

const StyledTextField = styled(MUITextField)`
  & .MuiFormLabel-root {
    color: gray;
  }
`;

export const TextField = ({ label, fullWidth, value, onChange }) => {
  return (
    <StyledTextField
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
    />
  );
};
