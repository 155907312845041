import { createTheme } from '@mui/material';

export const colors = {
  primary: '#00b8de',
  secondary: '#f7941d',
  background: '#f7f7f7',
};

const themeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      dark: colors.secondary,
    },
  },
  typography: {
    fontFamily: 'Montserrat, Prompt, sans-serif',
    h1: {
      fontWeight: 800,
      fontSize: '2rem', // 34px
      color: 'black',
    },
    h2: {
      color: 'black',
    },
    h3: {
      color: 'black',
    },
    h4: {
      fontWeight: 800,
      fontSize: '1.375rem', // 22px
      lineHeight: 'initial',
      color: 'black',
    },
    h5: {
      color: 'black',
    },
    h6: {
      fontWeight: 800,
      fontSize: '1rem', // 16px
    },
    body1: {
      color: 'black',
    },
    body2: {
      color: 'black',
    },
    subtitle1: {
      fontWeight: 700,
      fontsize: '0.875rem', // 14px;
      color: 'black',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.875rem', // 14px;
      color: 'black',
    },
    caption: {
      color: 'black',
    },
    overline: {
      color: 'black',
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #F3F3F3',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        text: {
          textDecoration: 'underline',
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
