import addresses from 'constants/ContractsAddress.json';

export const getPortalAddress = () => {
  return addresses.portal;
};

export const getBusdAddress = () => {
  return addresses.busd;
};

export const getPptkAddress = () => {
  return addresses.pptk;
};
